<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">votes and proceedings</div>
      </v-row>
      <v-row class="">
        <div class="">
          Precise summary of the proceedings of each sitting. The summary
          contains a record of what was done or transacted at the sitting in the
          order in which they were dealt with or occurred
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-card-title>
        <v-autocomplete
          label="Member"
          outlined
          :items="councilMemberItems"
          prepend-icon="mdi-account"
        ></v-autocomplete>

        <v-autocomplete
          prepend-icon="mdi-ballot"
          label="Vote Response"
          :items="voteResponseItems"
          outlined
        ></v-autocomplete>
      </v-card-title>
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date"
              item-key="id"
              show-expand
            >
              <template v-slot:[`item.meeting_date`]="{ item }">
                {{ item.voting_record.meeting_date | moment("Do MMM YYYY") }}
              </template>
              <template v-slot:[`item.meeting_subject`]="{ item }">
                {{ item.voting_record.meeting_subject }}
              </template>
              <template v-slot:[`item.result`]="{ item }">
                {{ item.results.votes }}
              </template>
              <template v-slot:expanded-item="{ item }">
                <v-list>
                  <v-subheader class="text-uppercase black--text"
                    >votes</v-subheader
                  >
                  <v-list-item
                    v-for="n of item.voting_record.votes"
                    :key="n.id"
                  >
                    <v-list-item-title>
                      {{ n.member.name }}
                    </v-list-item-title>
                    <v-list-item-action>
                      {{ n.member_vote }}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </template>
            </v-data-table>

            <!-- <v-dialog v-model="pdfDialog">
              <pdf-viewer />
            </v-dialog> -->
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
// import PdfViewer from "../PdfViewer.vue";
import Api from "@/services/Api";
export default {
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      // pdfDialog: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      councilMemberItems: [],
      commiteeItems: [],
      voteTypeItems: [],
      voteResponseItems: [],
      headers: [
        {
          text: "meeting date",
          value: "meeting_date",
          class: "text-uppercase black--text",
        },
        {
          text: "subject",
          value: "meeting_subject",
          width: "14%",
          class: "text-uppercase black--text",
        },
        {
          text: "result",
          value: "result",
          class: "text-uppercase black--text",
        },
      ],
      items: [],
    };
  },
  created() {
    Api.getVoteItems().then((res) => {
      this.items = res.data.data;
    });
  },
};
</script>

<style></style>
